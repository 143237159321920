import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";
import FieldValidationError from "../FieldError/FieldError";

interface WYSIWYGEditorProps {
  fieldName: string;
  label?: string;
  register: (options: { name: string }) => void;
  setValue: (name: string, value: string, shouldValidate?: boolean) => void;
  errors?: NestDataObject<any, FieldError>;
  currentValue?: string;
  size?: "small" | "medium" | "large";
  initialValue?: string;
  placeholder?: string;
}

const WYSIWYGEditor: React.FC<WYSIWYGEditorProps> = ({
  fieldName,
  label,
  register,
  setValue,
  errors,
  initialValue = "",
  placeholder = "",
  size = "medium",
}) => {
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "size",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "color",
    "background",
    "link",
    "image",
    "video",
    "script",
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ size: ["small", false, "large", "huge"] }],
      ["blockquote", "code-block"],
      ["link", "image", "video"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["clean"],
    ],
    clipboard: { matchVisual: false },
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: true,
    },
  };

  useEffect(() => {
    register({ name: fieldName });
  }, [register, fieldName]);

  const handleChange = (html: string) => {
    setValue(fieldName, html, true);
  };

  return (
    <div>
      {label && (
        <label htmlFor={fieldName} className="wysiwyg-editor__label">
          {label}
        </label>
      )}

      <ReactQuill
        className="custom-quill"
        theme="snow"
        defaultValue={initialValue}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        bounds=".app"
        placeholder={placeholder}
      />

      {errors?.[fieldName] && <FieldValidationError error={errors[fieldName]?.message || "Invalid input"} />}
      
      <style>
        {`
          .ql-toolbar .ql-formats button {
            font-size: 10px;
            padding: 4px 4px;
          }

          .ql-toolbar select {
            margin-left: 4px;
            font-size: 10px;
            padding: 4px;
          }

          .ql-container {
            min-height: ${size === "medium" ? "350px" : size === "small" ? "200px" : "500px"};
          }
        `}
      </style>
    </div>
  );
};

export default WYSIWYGEditor;

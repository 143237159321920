import React from "react";
import { Panel, RefinementList } from "react-instantsearch-dom";
import { GetReadableReferenceCategoryName } from "../../../../../helpers/enumHelpers";
import { IReferenceCategoryEnum } from "../../../../../interfaces/domain/enums/IReferenceCategoryEnum";

const PublishStateCategory: React.FunctionComponent = (props) => {
  return (
    <Panel>
      <div className="SearchFilter__Refinement">
        <h6>Filtering</h6>
        <RefinementList
          attribute="category"
          transformItems={(items: { label: string }[]) => {
            return items.map((item: { label: string }) => {
              const categoryEnum = item.label.toUpperCase() as IReferenceCategoryEnum;
              return { ...item, label: GetReadableReferenceCategoryName(categoryEnum) };
            });
          }}
          limit={15} 
        />
      </div>
    </Panel>
  );
};

export default PublishStateCategory;
